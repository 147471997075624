import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { SEOService } from './../../Services/seo.service';

declare const push_new_viewed_product_event: any;
declare const push_site_event: any;
declare const gtag: any;
@Component({
  selector: 'app-plan-and-pricing',
  templateUrl: './plan-and-pricing.component.html',
  styleUrls: ['./plan-and-pricing.component.css'],
})
export class PlanAndPricingComponent implements OnInit, OnDestroy {
  gmat_success_story_data: any;
  proven_solution_data: any;
  pricing_data: any;
  solution_detail_data: any;
  world_class_support_data: any;
  roi_data: any;
  gmat_journey_data: any;
  evaluation_data: any;
  concerns_data: any;
  scrollToBuyNow: boolean = false;
  all_data_loaded: boolean = false;
  link_tags;
  meta_tags;
  productType: string = 'SELF_PACED';

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute
  ) {
    if((this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.scroll == 'scroll-to-buy-now') || this.activatedRoute.snapshot.queryParams['scroll'] === 'scroll-to-buy-now'){
      this.scrollToBuyNow = true;
    }
    if((this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.productType)){
      this.productType = this.router.getCurrentNavigation().extras.state.productType;
    }
  }
  scrollIntoViews(){
    if(this.scrollToBuyNow){
      setTimeout(()=>document.getElementById('buy_now_table')?.scrollIntoView({ behavior: 'smooth' }));
    }
  }
  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('plan-and-pricing.json?version=' + version)
          .subscribe((response) => {
            this.gmat_success_story_data = response['gmat_success_story_data'];
            this.proven_solution_data = response['proven_solution_data'];
            this.solution_detail_data = response['solution_detail_data'];
            this.solution_detail_data["liveSessionSolutionView"] = false;
            this.world_class_support_data =
              response['world_class_support_data'];
            this.roi_data = response['roi_data'];
            this.gmat_journey_data = response['gmat_journey_data'];
            this.evaluation_data = response['evaluation_data'];
            this.concerns_data = response['concerns_data'];
            if (response['title']) {
              this.titleService.setTitle(response['title']);
            }
            if (response['meta_tags']) {
              this.meta_tags = response['meta_tags'];
              this.metaService.addTags(response['meta_tags']);
            }
            if(response['link_tags']){
              this.link_tags= response['link_tags'];
              this.seoService.createMultiLinks(response['link_tags']);
            }
            if(response['json_ld']){
              this.seoService.createJsonLd(response['json_ld']);
            }
            this.all_data_loaded = true;
          });
        this.httpService
          .getJson('products.json?version=' + version)
          .subscribe((response) => {
            this.pricing_data = response['pricing_data'];
            this.scrollIntoViews();
          });
      }
    });

    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
        var properties = {
          'Viewed_Product Name': 'GMAT Online',
          VP_Source: '/pricing/',
        };
        push_new_viewed_product_event('viewed product', properties);
      }
    });
    gtag('js', new Date());
    gtag('config', 'AW-1010091293');
  }

  getLiveSessionClick(result) {
    this.solution_detail_data["liveSessionSolutionView"] = result;
  }
  getLiveSessionClickFromSolution(result) {
    if(result){
      this.productType = 'LIVE_SESSIONS';
    }else{
      this.productType = 'SELF_PACED';
    }
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
