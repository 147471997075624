import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DataService {
  private getSlides = new BehaviorSubject<any>('');
  private _getEventScriptVersion$ = new BehaviorSubject<any>(0);
  private _getFromRegisterPage$ = new BehaviorSubject<any>(false);
  private _getEventScriptStatus$ = new BehaviorSubject<any>(false);
  private _getLottieEventScriptStatus$ = new BehaviorSubject<any>(false);
  private _getJsonVersion$ = new BehaviorSubject<any>(0);
  private _getWebinarEventName$ = new BehaviorSubject<any>(0);
  private _getWebinarPostRegPageUrl$ = new BehaviorSubject<any>(0);
  private _getUserName$ = new BehaviorSubject<any>('');
  private _getResetPasswordMail$ = new BehaviorSubject<any>('');
  private _getStripLoaded$ = new BehaviorSubject<any>(false);
  private _prodcutId$ = new BehaviorSubject<any>('');
  private _discountCode$ = new BehaviorSubject<any>('');
  private _courseComparisonClickedFromNav$ = new BehaviorSubject<boolean>(false);
  private _couponsLeftInDeal$ = new BehaviorSubject<any>('');
  private _dealEndDate$ = new BehaviorSubject<any>('');
  private _isDealLive$ = new BehaviorSubject<boolean>(false);
  private _timer$ = new BehaviorSubject<any>('');
  private _ctaBtnProperty$ = new BehaviorSubject<any>('')


  sharedSlides = this.getSlides.asObservable();

  public set updateEventScriptVersion(resetStatus: any) {
    this._getEventScriptVersion$.next(resetStatus);
  }

  public get getEventScriptVersion(): Observable<any> {
    return this._getEventScriptVersion$.asObservable();
  }

  public set updateFromRegisterPage(flag: any) {
    this._getFromRegisterPage$.next(flag);
  }

  public get getFromRegisterPage(): Observable<any> {
    return this._getFromRegisterPage$.asObservable();
  }

  public set updateEventScriptStatus(resetStatus: any) {
    this._getEventScriptStatus$.next(resetStatus);
  }

  public set updateJsonVersion(resetStatus: any) {
    this._getJsonVersion$.next(resetStatus);
  }

  public get getJsonVersion(): Observable<any> {
    return this._getJsonVersion$.asObservable();
  }

  public set updateWebinarEventName(resetStatus: any) {
    this._getWebinarEventName$.next(resetStatus);
  }

  public get getWebinarEventName(): Observable<any> {
    return this._getWebinarEventName$.asObservable();
  }

  public set updateWebinarPostRegPageUrl(resetStatus: any) {
    this._getWebinarPostRegPageUrl$.next(resetStatus);
  }

  public get getWebinarPostRegPageUrl(): Observable<any> {
    return this._getWebinarPostRegPageUrl$.asObservable();
  }

  public set updateUserName(resetStatus: any) {
    this._getUserName$.next(resetStatus);
  }

  public get getUserName(): Observable<any> {
    return this._getUserName$.asObservable();
  }

  public set updateStripLoaded(StripLoaded: any) {
    this._getStripLoaded$.next(StripLoaded);
  }

  public get getStripLoaded(): Observable<any> {
    return this._getStripLoaded$.asObservable();
  }

  public set updateResetPasswordMail(ResetPasswordMail: any) {
    this._getResetPasswordMail$.next(ResetPasswordMail);
  }

  public get getResetPasswordMail(): Observable<any> {
    return this._getResetPasswordMail$.asObservable();
  }

  public get getEventScriptStatus(): Observable<any> {
    return this._getEventScriptStatus$.asObservable();
  }

  public get getLottieEventScriptStatus(): Observable<any> {
    return this._getLottieEventScriptStatus$.asObservable();
  }

  public set updateLottieEventScriptStatus(resetStatus: any) {
    this._getLottieEventScriptStatus$.next(resetStatus);
  }

  updateSlides(item) {
    this.getSlides.next(item);
  }

  productId = this._prodcutId$.asObservable();
  discountCode = this._discountCode$.asObservable();
  courseComparisonClickedFromNav = this._courseComparisonClickedFromNav$.asObservable();
  couponsLeftInDeal = this._couponsLeftInDeal$.asObservable();
  dealEndDate = this._dealEndDate$.asObservable();
  isDealLive = this._isDealLive$.asObservable();
  getTimer = this._timer$.asObservable();
  ctaBtnProperty = this._ctaBtnProperty$.asObservable();

  updateProductId(id) {
    this._prodcutId$.next(id);
  }

  updateDiscountCode(discountCode) {
    this._discountCode$.next(discountCode);
  }

  updateCourseComparisonClickedFromNav(isClicked) {
    this._courseComparisonClickedFromNav$.next(isClicked);
  }

  updateCouponsLeftInDeal(coupons) {
    this._couponsLeftInDeal$.next(coupons);
  }

  updateDealEndDate(dealEndDate) {
    this._dealEndDate$.next(dealEndDate);
  }

  updateIsDealLive (flag) {
    this._isDealLive$.next(flag)
  }

  updateTimer( timerObj) {
    this._timer$.next(timerObj);
  }

  updatectaBtnProperty(prop) {
    this._ctaBtnProperty$.next(prop)
  }
}
