import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-what-students-says-about-gfe-v2',
  templateUrl: './what-students-says-about-gfe-v2.component.html',
  styleUrls: ['./what-students-says-about-gfe-v2.component.css'],
})
export class WhatStudentsSaysAboutGfeV2Component implements OnInit {
  cdn_url = environment.cdn_url;
  tab_background_name = 'img-testimonials bg';
  width = window.screen.width;
  active_index = 0;

  @Input() what_students_says_data;

  constructor() {
    if(this.width < 769){
      this.tab_background_name = 'img-testimonials bg mobile';
    }
  }

  ngOnInit(): void {}

  slideChange(flag) {
    if (flag) {
      if (
        this.active_index ==
        this.what_students_says_data['slides'].length - 1
      ) {
        this.active_index = 0;
      } else {
        this.active_index += 1;
      }
    } else {
      if (this.active_index == 0) {
        this.active_index = this.what_students_says_data['slides'].length - 1;
      } else {
        this.active_index -= 1;
      }
    }
  }
}
